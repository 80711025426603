import React from 'react'
import { Router } from '@reach/router'
import { Container } from 'semantic-ui-react'
import Layout from '../../components/Layout'
import { Refunds } from './Refunds'

const comp = props => {
  return (
    <Layout>
      <Container fluid style={{ margin: 40, padding: '0 40px' }}>
        <Router>
          <Refunds path="/refund/:order_id/:token" />
        </Router>
      </Container>
    </Layout>
  )
}

export default comp
