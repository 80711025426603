import React, { useState, useEffect, Fragment } from 'react'
import { Button, Card, Message, Radio, Container, Dimmer, Loader, Popup } from 'semantic-ui-react'
import { api_url } from '../../enum'

export function Refunds(props) {
  const testString =
    'A refund in the amount of $${amount} has been processed for Order #${order_id} to a Citrus Pear gift card.  It will come from no-reply@citruspeardinners.com, please check your spam folder. If you have not received it after an hour, please use the contact us form found at www.citruspeardinners.com/contact-us.  If this refund was processed in error, we can and will process your refund, please email your order number to refund_error@citruspeardinners.com.'
  const { token, order_id } = props
  const [message, setMessage] = useState('')
  const [message2, setMessage2] = useState('')
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    console.log(token, order_id)
    setMessage('')
    setMessage2('')
  }, [token, order_id])
  const refund = api_refund => {
    if (
      !window.confirm(
        `This will void the entirety of order# ${order_id}, are you ready to proceed?`
      )
    )
      return
    setLoading(true)
    setMessage('')
    setMessage2('')
    fetch(`${api_url}/refunds/togiftcard/${order_id}/${token}/${api_refund}`, {
      method: 'post'
    })
      .then(results => results.json())
      .then(data => {
        setLoading(false)
        console.log(data)
        setMessage(data.message)
        let m = data.message.split(/refund_error@citruspeardinners.com/)
        try {
          m = Array.isArray(m) ? (m.length > 1 ? m : [m[0], '']) : ['', '']
          setMessage(m[0])
          setMessage2(m[1])
        } catch (e) {
          console.log({ m })
          console.log(e)
        }
      })
      .catch(e => {
        setLoading(false)
        setMessage('An Error Occured, please contact Citrus Pear.')
      })
  }
  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
      }}
    >
      {message && (
        <Message info>
          <Message.Header>
            {message}{' '}
            {message2 ? (
              <>
                <a
                  target="_blank"
                  href={`mailto:refund_error@citruspeardinners.com?Subject=Oops!%20Order#${order_id}%20has%20been%20processed%20in%20error`}
                >
                  refund_error@citruspeardinners.com
                </a>
                {message2}
              </>
            ) : (
              ''
            )}
          </Message.Header>
        </Message>
      )}
      {loading && (
        <Dimmer active>
          <Loader />
        </Dimmer>
      )}
      <Container text style={{ marginTop: '7em' }}>
        <Card.Group>
          <Card style={{ width: '380px' }}>
            <Card.Content>
              <Card.Header>Fastest Refund</Card.Header>
              <Card.Meta>
                <Popup
                  inverted
                  wide="very"
                  header="How is this option the fastest?"
                  content="You will receive a Citrus Pear gift card, within an hour, in the 
                  amount of your entire order. 
                  This option is 7 to 10 days faster than refunding to your original
                  payment method due to bank processing.  You can use your gift card
                  on CitrusPearDinners.com for future purchases.
                  By selecting this option, you may also be able to keep any credit card
                  reward points you earned from your original purchase."
                  trigger={<a href="javascript:console.log('just read the popup.')">Why?</a>}
                />
              </Card.Meta>
              <Card.Description style={{ display: 'flex', flexDirection: 'row' }}>
                <div style={{ marginRight: 10 }}>
                  <Radio checked />
                </div>
                <div>
                  <strong>Refund to a Citrus Pear gift card</strong>
                  <br />
                  Estimated refund time: 1 minute to 1 hour after submitting request.
                </div>
              </Card.Description>
              <Button
                style={{ width: '100%', margin: '20px 0' }}
                color="primary"
                onClick={e => refund(false)}
              >
                Refund to Gift Card
              </Button>
              <Popup
                inverted
                wide="very"
                header="Refund to your Citrus Pear account"
                content="We'll apply your refund to a Citrus Pear gift card.  
                  The details of that gift card will be sent to the email associated with this order.  
                  If you don't recieve your refund email within an hour
                  please contact Citrus Pear for assistance."
                trigger={
                  <a href="javascript:console.log('How does it work? don\'t be lookin here.')">
                    How does it work?
                  </a>
                }
              />
              <br />
              <Popup
                inverted
                wide="very"
                header="You're in the right place"
                content={`You will want to click the refund button above. Don't delay making a purchase if you want
                a specific date or location.  Classes sell out quickly.  Once your refund is finalized, those meals
                will be available on CitrusPearDinners.com for purchase.`}
                trigger={
                  <a href="javascript:console.log('Change my order size or date? don\'t be lookin here.')">
                    I want to change my order (size or date)
                  </a>
                }
              />
            </Card.Content>
            <Card.Content extra>
              <Button
                style={{ width: '100%', height: 40 }}
                color="default"
                onClick={e => refund(true)}
              >
                Refund to your Credit Card
              </Button>
            </Card.Content>
          </Card>
        </Card.Group>
      </Container>
    </div>
  )
}

export default Refunds
